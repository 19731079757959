import {GenericContainer} from "@/components/generic/GenericContainer";
import SolutionItem from "@/components/solutions/SolutionItem";
import {GenericTitle} from "@/components/generic/GenericTitle";
import {useTranslations} from "next-intl";

export function SolutionComponent() {
	const t = useTranslations('');

	return (
		<GenericContainer className={"bg-white justify-center text-center grid gap-10 xl:gap-[52px]"}>
			<GenericTitle title={t("solutions.title")} description={t("solutions.description")}/>
			<GenericContainer className={"md:!grid-cols-3 grid !gap-12 !px-0 !py-0 max-w-container"}>
				<SolutionItem
					title={t("solutions.transactionMonitoring.title")}
					description={t("solutions.transactionMonitoring.description")}
					image={"/solutions/Transaction_Monitoring_Icon.svg"}
					href={"/solutions/transaction-monitoring"}
					buttonText={t("solutions.buttonTitle")}
				/>
				<SolutionItem
					title={t("solutions.paymentOptimization.title")}
					description={t("solutions.paymentOptimization.description")}
					image={"/solutions/Payment_Optimization_Icon.svg"}
					href={"/solutions/payment-optimization"}
					buttonText={t("solutions.buttonTitle")}
				/>
				<SolutionItem
					title={t("solutions.accountOpening.title")}
					description={t("solutions.accountOpening.description")}
					image={"/solutions/Account_Opening_Icon.svg"}
					href={"/solutions/account-opening"}
					buttonText={t("solutions.buttonTitle")}
				/>
				<SolutionItem
					title={t("solutions.accountProtection.title")}
					description={t("solutions.accountProtection.description")}
					image={"/solutions/Account_Protection_Icon.svg"}
					href={"/solutions/account-protection"}
					buttonText={t("solutions.buttonTitle")}
				/>
				<SolutionItem
					title={t("solutions.scamPrevention.title")}
					description={t("solutions.scamPrevention.description")}
					image={"/solutions/Scam_Prevention_Icon.svg"}
					href={"/solutions/scam-prevention"}
					buttonText={t("solutions.buttonTitle")}
				/>
				<SolutionItem
					title={t("solutions.amlKyc.title")}
					description={t("solutions.amlKyc.description")}
					image={"/solutions/AML&KYC_Icon.svg"}
					href={"/solutions/aml-kyc"}
					buttonText={t("solutions.buttonTitle")}
				/>
			</GenericContainer>
		</GenericContainer>
	)
}
